import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Switch } from "antd";
import cuid from "cuid";
import Processes from "entities/Processes";
import { FieldArray, Formik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import DatePicker from "shared/components/Form/DatePicker";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import {
  ATTACHMENT_TYPES,
  CONTACT_EXERCISE_FRAME,
  DELTA_END_TYPES,
  DEPARTMENTS,
  INSURANCE_PLAN_PARAMS_RULES,
  PAYMENT_FREQUENCIES,
  PAYMENT_TYPES,
  PERIOD_UNITS,
  SOCIAL_REGIMES,
} from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };
  return (
    <Formik
      initialValues={{
        commissions: [],
        params: [],
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({
        isValid,
        handleSubmit,
        setFieldValue,
        setValues,
        values,
        ...formProps
      }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key={"generalInfo"} divider={false} title={FORM.GENERAL_INFO}>
          <div className="grid--1">
            <div className="grid--3">
              <div className="d-flex">
                <span>{FORM.ACTIVE}</span>
                <Switch
                  className="button__switch"
                  checkedChildren={FORM.YES}
                  unCheckedChildren={FORM.NO}
                  defaultChecked={false}
                  onChange={(e, _) => setFieldValue("active", e)}
                />
              </div>
              <div className="d-flex">
                <span>{FORM.INTERNAL}</span>
                <Switch
                  className="button__switch"
                  checkedChildren={FORM.YES}
                  unCheckedChildren={FORM.NO}
                  defaultChecked={get(values, "internal")}
                  onChange={(e, _) => setFieldValue("internal", e)}
                />
              </div>
              <div className="d-flex">
                <span>{FORM.MADELIN}</span>
                <Switch
                  className="button__switch"
                  checkedChildren={FORM.YES}
                  unCheckedChildren={FORM.NO}
                  defaultChecked={false}
                  onChange={(e, _) => setFieldValue("fields.madelin", e)}
                />
              </div>
            </div>
            <div className="grid--2">
              <Input
                placeholder={FORM.NAME}
                label={FORM.NAME}
                {...bindInputProps({ name: "name", values, ...formProps })}
              />

              <Input
                placeholder={FORM.DESCRIPTION}
                label={FORM.DESCRIPTION}
                {...bindInputProps({
                  name: "description",
                  values,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.ALIAS}
                label={FORM.ALIAS}
                {...bindInputProps({ name: "alias", values, ...formProps })}
              />
              <DatePicker
                placeholder={FORM.DATE}
                label={FORM.DATE}
                placeholders={[FORM.START_DATE, FORM.END_DATE]}
                onChange={(_, dates) => setFieldValue("date", dates)}
              />
              <Input
                type="file"
                variant="uploader"
                listType="picture"
                showUploadList={false}
                maxCount={1}
                onUpload={([file]) => handleFileUpload(file, setFieldValue)}
                fileList={
                  isEmpty(get(values, "icon", [])) ? [] : [get(values, "icon")]
                }
              >
                <Button type="primary--link">{FORM.ICON}</Button>
                <div>
                  {get(values, "icon") && (
                    <img
                      className="icon"
                      alt="icon"
                      src={get(values, "icon")}
                    />
                  )}
                </div>
              </Input>
            </div>
          </div>
        </Card>,
        <div className="m-bottom-24" key="divider-1" />,
        // <Card title={FORM.COMMISSIONS.TITLE} divider={false} key="commission">
        //   <div className="grid--4">
        //     <Select
        //       type="async"
        //       mode="multiple"
        //       node={{
        //         query: queries.COMMISSIONS,
        //         accessors: {
        //           root: "commissions.data",
        //           label: {
        //             separator: "/",
        //             keys: ["value", "withholding/value"],
        //           },
        //         },
        //       }}
        //       placeholder={FORM.COMMISSIONS.TITLE}
        //       label={FORM.COMMISSIONS.TITLE}
        //       {...bindInputProps({
        //         name: "commissions",
        //         values,
        //         ...formProps,
        //       })}
        //       onChange={(value) => setFieldValue("commissions", value)}
        //     />
        //   </div>
        // </Card>,
        <div className="m-bottom-24" key="divider-2" />,
        <Card
          divider={false}
          title={FORM.CONDITIONS.PAYMENT_TITLE}
          key="conditions"
        >
          <Col span={24}>
            <Row gutter={[16, 16]} align={"middle"}>
              <Col span={24}>
                <div className="grid--3 m-bottom-16">
                  <Input
                    type="checkbox"
                    label={FORM.TNS}
                    {...bindInputProps({ name: "fields.tns", ...formProps })}
                    checked={!!get(values, "fields.tns")}
                  />
                  <Input
                    type="checkbox"
                    label={FORM.CONDITIONS.ALLOWED_PAYER}
                    {...bindInputProps({
                      name: "fields.payment.shouldBeAdherent",
                      ...formProps,
                    })}
                    checked={!!get(values, "fields.payment.shouldBeAdherent")}
                  />
                </div>
              </Col>
              <Col span={8}>
                <Select
                  mode="multiple"
                  options={Object.entries(PAYMENT_TYPES).map(
                    ([key, value]) => ({
                      value,
                      label: FORM.CONDITIONS.PAYMENT_TYPE.OPTIONS[key],
                    })
                  )}
                  placeholder={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
                  label={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
                  {...bindInputProps({
                    name: "fields.payment.type",
                    values,
                    ...formProps,
                  })}
                  onChange={(value) =>
                    setFieldValue(`fields.payment.type`, value)
                  }
                />
              </Col>
              <Col span={8}>
                <Select
                  mode="multiple"
                  options={Object.entries(PAYMENT_FREQUENCIES).map(
                    ([key, value]) => ({
                      value,
                      label: FORM.CONDITIONS.PAYMENT_FREQUENCY.OPTIONS[key],
                    })
                  )}
                  placeholder={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
                  label={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
                  {...bindInputProps({
                    name: "fields.payment.frequency",
                    values,
                    ...formProps,
                  })}
                  onChange={(value) =>
                    setFieldValue(`fields.payment.frequency`, value)
                  }
                />
              </Col>
              <Col span={8}>
                <Input
                  type="checkbox"
                  label={FORM.CONDITIONS.ALLOWED_PAYER}
                  {...bindInputProps({
                    name: "fields.payment.shouldBeAdherent",
                    ...formProps,
                  })}
                  checked={!!get(values, "fields.payment.shouldBeAdherent")}
                />
              </Col>
            </Row>
          </Col>
        </Card>,
        <div key="margin-3" className="m-bottom-16" />,
        <Card divider={false} title={FORM.CONDITIONS.TITLE} key="rules">
          <FieldArray
            name={"params"}
            render={(arrayHelpers) => [
              get(values, "params", []).map((_, i) => [
                <Col span={24} key="conditions">
                  <div key="params" className="grid--1">
                    <div
                      className={"d-flex justify--between m-top-8 delete-icon"}
                    >
                      <h3 className="card--title">{`${
                        FORM.CONDITIONS.PARAMETERS
                      } ${i + 1}`}</h3>
                      <BiTrash
                        color={COLORS.C_DANGER}
                        size={24}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      />
                    </div>

                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE}</Col>
                      <Col sm={24} md={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                          <Col flex="1">
                            <Input
                              type="number"
                              placeholder={FORM.CONDITIONS.START_DATE}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_START}`,
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={24} md={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col flex={1}>
                            <Select
                              options={Object.entries(DELTA_END_TYPES).map(
                                ([key, value]) => ({
                                  label:
                                    FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                      key
                                    ],
                                  value,
                                })
                              )}
                              placeholder={FORM.CONDITIONS.END_DATE}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END}.type`,
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                          {get(
                            values,
                            `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END}.type`
                          ) === DELTA_END_TYPES.ADD_PERIOD && (
                            <>
                              <Col span={9}>
                                <Input
                                  type="number"
                                  placeholder={FORM.CONDITIONS.NUMBER_OF}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END}.value`,
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                              <Col span={5}>
                                <Select
                                  options={Object.entries(PERIOD_UNITS).map(
                                    ([key, value]) => ({
                                      label: FORM.CONDITIONS[key],
                                      value,
                                    })
                                  )}
                                  placeholder={FORM.CONDITIONS.UNIT}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END}.unit`,
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE_RIA}</Col>
                      <Col span={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                          <Col flex="1">
                            <Input
                              type="number"
                              placeholder={FORM.CONDITIONS.START_DATE_RIA}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA}`,
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col flex={1}>
                            <Select
                              options={Object.entries(DELTA_END_TYPES).map(
                                ([key, value]) => ({
                                  label:
                                    FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                      key
                                    ],
                                  value,
                                })
                              )}
                              placeholder={FORM.CONDITIONS.END_DATE_RIA}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA}.type`,
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                          {get(
                            values,
                            `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA}.type`
                          ) === DELTA_END_TYPES.ADD_PERIOD && (
                            <>
                              <Col span={9}>
                                <Input
                                  type="number"
                                  placeholder={FORM.CONDITIONS.NUMBER_OF}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA}.value`,
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                              <Col span={5}>
                                <Select
                                  options={Object.entries(PERIOD_UNITS).map(
                                    ([key, value]) => ({
                                      label: FORM.CONDITIONS[key],
                                      value,
                                    })
                                  )}
                                  placeholder={FORM.CONDITIONS.UNIT}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA}.unit`,
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.AGE}</Col>
                      <Col span={10}>
                        <Input
                          type="number"
                          placeholder={FORM.CONDITIONS.MIN}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: `rules.${INSURANCE_PLAN_PARAMS_RULES.MIN_AGE}`,
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                      <Col sm={10}>
                        <Input
                          type="number"
                          placeholder={FORM.CONDITIONS.MAX}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: `rules.${INSURANCE_PLAN_PARAMS_RULES.MAX_AGE}`,
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.SOCIAL_REGIMES}</Col>
                      <Col sm={20} lg={10}>
                        <Select
                          mode="multiple"
                          options={Object.entries(SOCIAL_REGIMES).map(
                            ([key, value]) => ({
                              value,
                              label:
                                FORM.CONDITIONS.SOCIAL_REGIMES_OPTIONS[key],
                            })
                          )}
                          placeholder={FORM.CONDITIONS.SOCIAL_REGIMES}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: `rules.${INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES}`,
                            values,
                            ...formProps,
                          })}
                          value={
                            get(
                              values,
                              `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES}`
                            ) || []
                          }
                          onChange={(value) =>
                            setFieldValue(
                              `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES}`,
                              value
                            )
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.EXERCISE_FRAME.LABEL}</Col>
                      <Col span={20}>
                        <Input
                          type="radio"
                          variant="group"
                          values={[
                            {
                              value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                              label:
                                FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.EMPLOYEE,
                            },
                            {
                              value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                              label:
                                FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS
                                  .INDEPENDENT,
                            },
                            {
                              value: "",
                              label: FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.ANY,
                            },
                          ]}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: `rules.${INSURANCE_PLAN_PARAMS_RULES.EXERCISE_FRAME}`,
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.DEPARTMENTS}</Col>
                      <Col sm={20} lg={10}>
                        <Select
                          mode="multiple"
                          options={DEPARTMENTS.map((value) => ({
                            value,
                            label: value,
                          }))}
                          placeholder={FORM.CONDITIONS.DEPARTMENTS}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: `rules.${INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS}`,
                            values,
                            ...formProps,
                          })}
                          value={
                            get(
                              values,
                              `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS}`
                            ) || []
                          }
                          onChange={(value) =>
                            setFieldValue(
                              `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS}`,
                              value
                            )
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.PROCESSES}</Col>
                      <Col sm={20} lg={10}>
                        <Processes
                          NoData={() => <></>}
                          View={({ data }) => (
                            <Select
                              mode="multiple"
                              options={data?.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))}
                              placeholder={FORM.CONDITIONS.PROCESSES}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: `rules.${INSURANCE_PLAN_PARAMS_RULES.PROCESSES}`,
                                values,
                                ...formProps,
                              })}
                              value={
                                get(
                                  values,
                                  `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.PROCESSES}`
                                ) || []
                              }
                              onChange={(value) =>
                                setFieldValue(
                                  `params.${i}.rules.${INSURANCE_PLAN_PARAMS_RULES.PROCESSES}`,
                                  value
                                )
                              }
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>,
              ]),
              <div className="m-top-16" key="divider" />,
              <Button
                type="primary"
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({
                    id: cuid(),
                    rules: {},
                  });
                }}
              >
                <PlusOutlined color={COLORS.C_WHITE} />
              </Button>,
            ]}
          />
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
