import { useQuery } from '@apollo/client';
import { useTranslation } from "react-i18next";
import ActivitiesLog from "shared/components/ActivitiesLog";
import Loading from 'shared/components/Spin';
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTIONS } from 'utils/api/graphql/queries/subscription';

const SubscriptionActivities = ({ projectId }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);

  const variables = {
    where: {
      project: {
        id: projectId,
      },
    },
    take: 10,
    skip: 0,
  };

  const { loading, error, data } = useQuery(SUBSCRIPTIONS, {
    variables,
  });

  if (loading) return <Loading />;
  if (error) return <div>{error.message}</div>;

  const activities = data?.subscriptions?.data || [];

  if (activities.length === 0) {
    return <div className='no-activity'>{t('ACTIVITIES.NO_ACTIVITY')}</div>;
  }

  return (
    <ActivitiesLog activities={activities} />
  );
};

export default SubscriptionActivities;
