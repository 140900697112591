import { message, Modal } from "antd";
import { find, get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  MODAL_STATES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATE_TYPES,
  TEMPLATES,
  TRACER_TYPES,
} from "utils/constants";
import CopyOrSendLink from "./CopyOrSendLink";
import Initial from "./GenerateLink";

const GenerateLinkModal = ({ open, setOpen }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const GENERATE_LINK = t("LIST.SUBSCRIPTION.GENERATE_SUBSCRIPTION_LINK", {
    returnObjects: true,
  });

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [addLink] = useMutation(CREATE_LINK);
  const { data } = useQuery(TRACER, {
    variables: { where: { type: TRACER_TYPES.COMPARISON } },
  });
  const onClose = () => setOpen({});

  const onGenerateLink = () => {
    const template = get(
      TEMPLATES,
      `${PROCESS_TYPES.B2C}.THEMES.${TEMPLATE_TYPES.RADIANCE}.${
        open.isInfoCompleted ? "JUSTIFICATIONS" : "B2C_SUBSCRIPTION"
      }`
    );
    setSelectedOption(template);
    const statuses = get(data, "tracer.flow.process.processStatuses", []);
    const selectedStatus = find(statuses, { template })?.id;
    addLink({
      variables: {
        data: {
          url: process.env.REACT_APP_B2C_URL,
          subscription: { id: open.subscriptionId },
          project: { id: open.projectId },
          fields: {
            process: { id: get(data, "tracer.flow.process.id") },
            status: { id: selectedStatus },
          },
        },
      },
      onCompleted: ({ addLink }) => {
        setOpen((prev) => ({
          ...prev,
          link: `${process.env.REACT_APP_B2C_URL}/?l=${addLink.id}`,
        }));
      },
    });
  };

  useEffect(() => {
    if (open?.projectId && open?.subscriptionId) onGenerateLink();
  }, [open.projectId, open.subscriptionId]);

  const onCopyLink = () => {
    navigator.clipboard.writeText(open?.link);
    message.success(GENERATE_LINK.COPIED);
    setOpen((prev) => ({ ...prev, copied: true }));
  };

  const onSendLink = () => {
    navigate(SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.SEND_LINK.path, {
      state: open,
    });
  };

  const CONTENT_STATE = {
    [MODAL_STATES.INITIAL]: <Initial onGenerateLink={onGenerateLink} />,
    [MODAL_STATES.DONE]: (
      <CopyOrSendLink
        onCopyLink={onCopyLink}
        onSendLink={onSendLink}
        link={open?.link}
        selectedOption={selectedOption}
        linkCopied={open?.copied}
      />
    ),
  };
  return (
    <Modal
      title={GENERATE_LINK[selectedOption]?.TITLE}
      open={open?.projectId && open?.subscriptionId}
      onCancel={onClose}
      footer={null}
      className="generate-link-modal"
    >
      {CONTENT_STATE[MODAL_STATES.DONE]}
    </Modal>
  );
};

export default GenerateLinkModal;
