import { Drawer } from "antd";
import { find, get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { MODAL_STATES } from "utils/constants";
import Done from "./DrawerContent/Done";
import Initial from "./DrawerContent/Initial";

const SignProjectDrawer = ({
  projectId,
  onClose,
  data,
  onSign,
  setState,
  state,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const SIGN_PROJECT = t("LIST.SIGN_PROJECT", { returnObjects: true });
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const options = data.map(({ status, id, insurancePlan, additionalInfo }) => {
    return {
      label: get(insurancePlan, "name"),
      status: `${SIGN_PROJECT.STATUS} : ${SIGN_PROJECT.STATUSES[status]}`,
      value: id,
      disabled: !(
        additionalInfo?.isInfoCompleted && additionalInfo?.isDocumentsCompleted
      ),
      popover: !(
        additionalInfo?.isInfoCompleted && additionalInfo?.isDocumentsCompleted
      )
        ? SIGN_PROJECT.POPOVER
        : "",
    };
  });

  const DRAWER_CONTENT = {
    [MODAL_STATES.INITIAL]: (
      <Initial
        setState={setState}
        onSign={onSign}
        options={options}
        selectedSubscription={selectedSubscription}
        setSelectedSubscription={setSelectedSubscription}
      />
    ),
    [MODAL_STATES.DONE]: (
      <Done subscription={find(data, { id: selectedSubscription })} />
    ),
  };

  return (
    <Drawer
      className="drawer__area"
      title={SIGN_PROJECT.TITLE}
      width={500}
      onClose={onClose}
      open={!!projectId}
      placement="right"
    >
      {DRAWER_CONTENT[state]}
    </Drawer>
  );
};

export default SignProjectDrawer;
