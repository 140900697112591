import { useTranslation } from "react-i18next";
import Icon from "shared/components/Icon";
import { NAME_SPACES } from "shared/locales/constants";
import { formatDate } from "utils/helpers/date";

const mapStatus = (status, TRANSLATION) => {
  switch (status) {
    case 'DRAFT':
      return {
        label: TRANSLATION.STATUS.DRAFT,
        icon: <Icon name="convert_to_text" size={24} />
      };
    case 'SEND':
      return {
        label: TRANSLATION.STATUS.SEND,
        icon: <Icon name="gesture_select" size={24} />
      };
    case 'SIGNED':
      return {
        label: TRANSLATION.STATUS.SIGNED,
        icon: <Icon name="draw" size={24} />
      };
    default:
      return {
        label: TRANSLATION.STATUS.DRAFT,
        icon: <Icon name="convert_to_text" size={24} />
      };
  }
};

const ActivityItem = ({ activity }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const { status, signedDate, createdDate } = activity;
  const TRANSLATION = t("ACTIVITIES", { returnObjects: true });
  const { label, icon } = mapStatus(status, TRANSLATION);

  return (
    <div className="activities__log--item">
      <div className="icon">
        {icon}
      </div>
      <div className="content">
        <div className="line">
          <span className="status">{label} </span>
          {/* {TRANSLATION.WITH}
          <span className="name">{project.author.firstname} {project.author.lastname}</span> */}
        </div>
        <span className="date">
          {formatDate(signedDate || createdDate)}
        </span>
      </div>
    </div>
  );
};

export default ActivityItem;
