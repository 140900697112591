import dayjs from "dayjs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "utils/constants";

export const isMajor = dayjs().subtract(18, "years");

export const hundredYearsAgoStart = dayjs()
  .subtract(100, "year")
  .startOf("year");

export const twentySevenYearsAgoStart = dayjs()
  .subtract(27, "year")
  .startOf("year");

export const displayDate = ({
  date,
  format = DATE_TIME_FORMAT,
  defaultValue = "-",
}) =>
  date && moment(date).isValid() ? moment(date).format(format) : defaultValue;



export const getInitials = (firstname, lastname) => {
  if (!firstname || !lastname) return '';
  return `${firstname[0].toUpperCase()}${lastname[0].toUpperCase()}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};