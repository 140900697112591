import { notification } from "antd";
import cuid from "cuid";
import GeneralCosts from "entities/GeneralCosts";
import { find, get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT,
  GENERATE_QUOTE_DOCUMENT,
} from "utils/api/graphql/mutations/attachment";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  PROCESS_TYPES,
  TEMPLATE_TYPES,
  TEMPLATES,
  TRACER_TYPES,
} from "utils/constants";
import { removeDuplicates } from "utils/helpers/array";
import View from "./View";

const Quote = ({ project, onNext, onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [generateDutyOfAdviceQuoteDocument] = useMutation(
    GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT
  );
  const [generateQuoteDocument] = useMutation(GENERATE_QUOTE_DOCUMENT);

  const [attachments, setAttachments] = useState([
    {
      name: DETAIL.TEMPLATES.QUOTE.QUOTE_FILE,
      contentType: "application/pdf",
    },
    {
      name: DETAIL.TEMPLATES.QUOTE.DUTY_FILE,
      contentType: "application/pdf",
    },
  ]);

  const { loading } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: {
      isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      where: {
        OR: get(project, "fields.lastSelected", [])?.map((id) => ({
          AND: [{ insurancePlans: { id } }, { project: null }],
        })),
      },
    },
    onCompleted: (data) => {
      const uniqAttachments = removeDuplicates(
        get(data, "attachments.data", [])
      );
      setAttachments((prev) => [...prev, ...uniqAttachments]);
    },
  });

  const [addLink] = useMutation(graphql.mutations.CREATE_LINK);
  const { data: tracer } = useQuery(TRACER, {
    variables: { where: { type: TRACER_TYPES.COMPARISON } },
  });

  const [sendQuoteMutation] = useMutation(graphql.mutations.SEND_QUOTE, {
    onCompleted: (_) => {
      notification.open({
        message: DETAIL.TEMPLATES.QUOTE.NOTIFICATION.SUCCESS,
        duration: 5,
        type: "success",
      });
      onNext({
        payload: {
          locked: true,
          fields: {
            ...project.fields,
            insurancePlans: [
              ...get(project, "fields.insurancePlans", []),
              ...get(project, "fields.lastSelected", []),
            ],
          },
        },
      });
    },
  });
  const [updateContact] = useMutation(graphql.mutations.UPDATE_CONTACT, {
    onCompleted: async () => {
      let links = {};
      get(project, "fields.lastSelected", []).forEach((insurancePlanId) => {
        addSubscription({
          variables: {
            data: {
              id: cuid(),
              project: { id: project.id },
              insurancePlan: {
                id: insurancePlanId,
              },
            },
          },
          onCompleted: ({ addSubscription }) => {
            if (!get(project, "fields.addLinks")) return;
            const statuses = get(
              tracer,
              "tracer.flow.process.processStatuses",
              []
            );
            const selectedStatus = find(statuses, {
              template:
                TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
                  .B2C_SUBSCRIPTION,
            })?.id;
            addLink({
              variables: {
                data: {
                  url: process.env.REACT_APP_B2C_URL,
                  subscription: { id: addSubscription.id },
                  project: { id: project.id },
                  fields: {
                    process: { id: get(tracer, "tracer.flow.process.id") },
                    status: { id: selectedStatus },
                  },
                },
              },
              onCompleted: ({ addLink }) => {
                links = {
                  ...links,
                  [insurancePlanId]: `${process.env.REACT_APP_B2C_URL}/?l=${addLink.id}`,
                };
              },
            });
          },
        });
      });
      await generateDutyOfAdviceQuoteDocument({
        variables: {
          data: {
            project: { id: project.id },
            insurancePlans: get(project, "fields.lastSelected", []).map(
              (id) => ({
                id,
              })
            ),
          },
        },
      });
      generateQuoteDocument({
        variables: {
          data: {
            project: { id: project.id },
            insurancePlans: get(project, "fields.lastSelected", []).map(
              (id) => ({
                id,
              })
            ),
          },
        },
        onCompleted: () => {
          sendQuoteMutation({
            variables: {
              data: {
                links,
                project: { id: project.id },
                insurancePlans: get(project, "fields.lastSelected", []).map(
                  (id) => ({
                    id,
                  })
                ),
              },
            },
          });
        },
      });
    },
  });
  const sendQuote = (telecoms) => {
    updateContact({
      variables: {
        where: {
          id: project.contact.id,
        },
        data: {
          telecoms: [
            ...project.contact.telecoms.filter(
              (telecom) => telecom.system !== CONTACT_POINT_SYSTEM.EMAIL
            ),
            ...telecoms.map(({ label, value }) => ({
              id: value,
              system: CONTACT_POINT_SYSTEM.EMAIL,
              value: label,
            })),
          ],
        },
      },
    });
  };

  const generalCostsFilter = {
    where: {
      project: {
        id: project.id,
      },
    },
    isIn: {
      insurancePlan: {
        id: get(project, "fields.lastSelected", []),
      },
    },
  };

  return (
    <GeneralCosts
      filter={generalCostsFilter}
      loading={loading}
      updateContact={updateContact}
      attachments={attachments}
      setAttachments={setAttachments}
      project={project}
      onNext={sendQuote}
      onBack={onBack}
      View={View}
      NoData={View}
    />
  );
};

export default Quote;
