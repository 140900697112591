import {
  MdDownload,
  MdForwardToInbox,
  MdReportGmailerrorred,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import InsurancePlan from "./InsurancePlan";

const Initial = ({
  data,
  onSendQuote,
  onDownload,
  onCheck,
  onJustify,
  justifications,
  selectedData,
  translation,
  insurancePlans = [],
  addLinks,
  setAddLinks,
  onCopyLink,
}) => {
  return (
    <div className="grid--1">
      <div>
        <div className="download__header">{translation.TITLE}</div>
      </div>
      <div className="grid--1">
        <Alert
          type="info"
          showIcon
          message={translation.SUGGESTION}
          icon={<MdReportGmailerrorred size={16} />}
        />
        {data.map((item, index) => (
          <div key={"product-" + index}>
            <InsurancePlan
              onCheck={(state) => onCheck(item?.insurancePlan?.id, state)}
              checked={[...selectedData, ...insurancePlans].includes(
                item?.insurancePlan?.id
              )}
              logo={item.insurancePlan.icon}
              name={item.insurancePlan.name}
              description={item.insurancePlan.description}
              cost={item.cost}
              currency={item.currency}
              translation={translation}
              justification={justifications[item?.insurancePlan?.id]}
              onJustify={(justification) =>
                onJustify(justification, item?.insurancePlan?.id)
              }
              checkDisabled={selectedData.length > 3}
              disabled={insurancePlans.includes(item?.insurancePlan?.id)}
            />
          </div>
        ))}
      </div>
      <div className="download__actions">
        <div className="download__actions--container">
          <Button
            onClick={onDownload}
            disabled={!selectedData.length}
            type="primary--outlined"
            size="full--width"
          >
            {translation.DOWNLOAD}
            <MdDownload size={16} />
          </Button>
          <div className="download__actions--copy" onClick={onCopyLink}>
            {translation.COPY}
          </div>
        </div>
        <span className="download__actions--or">{translation.OR}</span>
        <div className="download__actions--container">
          <Button
            onClick={onSendQuote}
            disabled={!selectedData.length}
            type="primary"
            size="full--width"
          >
            {translation.SEND_BY_EMAIL}
            <MdForwardToInbox size={16} />
          </Button>
          <Input
            type="checkbox"
            label={translation.ADD_LINKS}
            checked={addLinks}
            onChange={({ target: { checked } }) => {
              setAddLinks(checked);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Initial;
