import { Drawer, notification } from "antd";
import { find, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "shared/components/Avatar";
import Tabs from "shared/components/Tab";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { UPDATE_CONTACT } from "utils/api/graphql/mutations/contacts";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import Activities from "./Tabs/Activities";
import Information from "./Tabs/Information";
import Notes from "./Tabs/Notes";
import Projects from "./Tabs/Projects";

const View = ({
  contact,
  active,
  setActive,
  project,
  projects,
  drawer,
  onClose,
  onAssign,
  isAssigning,
  tracer,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const DRAWER = t("DRAWER", { returnObjects: true });
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [drawerWidth, setDrawerWidth] = useState("520px");

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setDrawerWidth("95%");
      } else if (windowWidth <= 1024) {
        setDrawerWidth("70%");
      } else {
        setDrawerWidth("520px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleUpdateEmail = (newEmail) => {
    const existingEmailTelecom = find(contact.telecoms, { system: CONTACT_POINT_SYSTEM.EMAIL });

    updateContact({
      variables: {
        where: { id: contact.id },
        data: {
          telecoms: [
            ...omitDeep(contact.telecoms, "__typename").filter(
              (telecom) => telecom.system !== CONTACT_POINT_SYSTEM.EMAIL
            ),
            {
              id: get(existingEmailTelecom, 'id', null),
              system: CONTACT_POINT_SYSTEM.EMAIL,
              value: newEmail,
            },
          ],
        },
      },
    })
      .then(() => {
        notification.success({
          message: DRAWER.TABS.INFORMATION.UPDATE_SUCCESS,
          closeIcon: null,
        });
      })
      .catch((error) => {
        notification.error({
          message: DRAWER.TABS.INFORMATION.UPDATE_ERROR,
          closeIcon: null,
        });
      });
  };


  const handleTabChange = (key) => {
    setActive(key);
  };

  const onCloseDrawer = () => {
    onClose();
    setActive("INFORMATION");
  };

  const TABS = [
    {
      key: "INFORMATION",
      children: (
        <Information project={project} translate={TRANSLATION} data={contact} onUpdateEmail={handleUpdateEmail} />
      ),
      label: DRAWER.TABS.INFORMATION.TITLE,
    },
    {
      key: "PROJECTS",
      children: (
        <Projects
          translate={TRANSLATION}
          tracer={tracer}
          isAssigning={isAssigning}
          onAssign={onAssign}
          projects={projects}
        />
      ),
      label: `${DRAWER.TABS.PROJECTS.TITLE} (${projects?.count})`,
    },
    {
      key: "ACTIVITIES",
      children: <Activities projectId={project?.id} />,
      label: DRAWER.TABS.ACTIVITIES.TITLE,
    },
    {
      key: "NOTES",
      children: <Notes projectId={project?.id} />,
      label: DRAWER.TABS.NOTES.TITLE,
    },
  ];

  return (
    <Drawer
      title={
        <>
          <h2>{DRAWER.TITLE}</h2>
          <div>
            <Avatar
              mode={"text"}
              size={"small"}
              value={`${get(contact, "user.firstname", "")?.substring(
                0,
                1
              )}${get(contact, "user.lastname", "")?.substring(0, 1)}`}
            />
            <span>
              {get(contact, "user.firstname")} {get(contact, "user.lastname")}
            </span>
          </div>
        </>
      }
      placement="right"
      onClose={onCloseDrawer}
      open={drawer}
      className="contact__drawer"
      contentWrapperStyle={{
        width: drawerWidth,
      }}
    >
      <Tabs items={TABS} activeKey={active} onChange={handleTabChange} />
    </Drawer>
  );
};
export default View;
