import ActivityItem from './item';

const Activities = ({ activities }) => {
  const filteredActivities = activities.filter(
    (activity, index, self) =>
      index === self.findIndex(
        (a) =>
          a.status === activity.status

      )
  );
  return (
    <div className="activities__log">
      {filteredActivities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
    </div>
  );
};

export default Activities;


