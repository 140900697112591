import { message, Modal } from "antd";
import { find, get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  MODAL_STATES,
  PROCESS_TYPES,
  TEMPLATE_TYPES,
  TEMPLATES,
  TRACER_TYPES,
} from "utils/constants";
import Done from "./Done";
import Initial from "./Initial";
import Pending from "./Pending";

const DownloadOrSend = ({
  data,
  open,
  onClose,
  onSendQuote,
  onDownload,
  fields,
  insurancePlans,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [state, setState] = useState(MODAL_STATES.INITIAL);
  const [addLinks, setAddLinks] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [addLink] = useMutation(CREATE_LINK);
  const { data: tracer } = useQuery(TRACER, {
    variables: { where: { type: TRACER_TYPES.COMPARISON } },
  });
  const [justifications, setJustifications] = useState(
    get(
      fields,
      "justifications",
      data.reduce((acc, { insurancePlan: { id } }) => {
        acc[id] = "";
        return acc;
      }, {})
    )
  );
  const onCheck = (id, state) => {
    if (state) return setSelectedData((prev) => [...prev, id]);
    setSelectedData((prev) => [...prev.filter((item) => item !== id)]);
    onJustify(undefined, id);
  };

  const onJustify = (justification, insuranceId) =>
    setJustifications((prev) => ({
      ...prev,
      [insuranceId]: justification,
    }));

  const sendQuote = () => {
    onSendQuote({
      selectedData,
      payload: {
        fields: {
          ...fields,
          insurancePlans: [
            ...get(fields, "insurancePlans", []),
            ...selectedData,
          ],
          lastSelected: selectedData,
          addLinks,
          justifications,
        },
      },
    });
    onClose();
  };

  const download = () => {
    onDownload({
      setState,
      setSelectedData,
      selectedData,
      payload: {
        locked: true,
        fields: {
          ...fields,
          insurancePlans: [
            ...get(fields, "insurancePlans", []),
            ...selectedData,
          ],
          justifications,
        },
      },
    });
  };

  const onCopyLink = () => {
    const statuses = get(tracer, "tracer.flow.process.processStatuses", []);
    const template =
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE].PRODUCTS;
    const selectedStatus = find(statuses, { template })?.id;
    addLink({
      variables: {
        data: {
          url: process.env.REACT_APP_B2C_URL,
          project: { id },
          fields: {
            process: { id: get(tracer, "tracer.flow.process.id") },
            status: { id: selectedStatus },
          },
        },
      },
      onCompleted: ({ addLink }) => {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_B2C_URL}/?l=${addLink.id}`
        );
        message.success(DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND.COPIED);
      },
    });
  };

  const MODAL_STATE = {
    [MODAL_STATES.INITIAL]: (
      <Initial
        data={data}
        onSendQuote={sendQuote}
        onDownload={download}
        onCheck={onCheck}
        onJustify={onJustify}
        justifications={justifications}
        selectedData={selectedData}
        insurancePlans={insurancePlans}
        translation={DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND}
        addLinks={addLinks}
        setAddLinks={setAddLinks}
        onCopyLink={onCopyLink}
      />
    ),
    [MODAL_STATES.PENDING]: (
      <Pending translation={DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND} />
    ),
    [MODAL_STATES.DONE]: (
      <Done translation={DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND} />
    ),
  };

  return (
    <Modal open={open} onCancel={onClose} width={"60%"} footer={null}>
      {MODAL_STATE[state]}
    </Modal>
  );
};

export default DownloadOrSend;
