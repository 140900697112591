import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "@bit/taillislabs.pega.button";
import Header from "@bit/taillislabs.pega.header";
import Label from "@bit/taillislabs.pega.label";
import { Col, Row } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "shared/components/Form";
import Input from "shared/components/Form/Input";
import Modal from "shared/components/Modal";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { INPUT_TYPES, QUALIFIER_TYPES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, id, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFIER);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const QUALIFIERS = t("QUALIFIERS", { returnObjects: true });

  const [selected, setSelected] = useState({ visible: null });

  const TEMPLATES = {
    null: () => <></>,
    [INPUT_TYPES.TEXT]: () => <></>,
    [INPUT_TYPES.DATE]: () => <></>,
    [INPUT_TYPES.SELECT]: ({ translation, values, formProps }) => (
      <Modal
        type="info"
        key="modal"
        okText={translation.MODAL.SAVE}
        cancelText={translation.MODAL.CANCEL}
        onCancel={() => setSelected({ visible: null })}
        onOk={() => setSelected({ visible: null })}
        visible={true}
        width={1000}
        child={
          <Row gutter={[16, 16]} key="statuses">
            <FieldArray
              name={`requirements.${selected.index}.options`}
              render={(arrayHelpers) => [
                get(values.requirements[selected.index], "options", []).map(
                  (__, idx) => [
                    <Col lg={9} key={`label-${__.id}`}>
                      <Input
                        placeholder={FORM.REQUIREMENTS.OPTIONS.LABEL}
                        {...bindArrayInputProps({
                          parent: `requirements.${selected.index}.options`,
                          index: idx,
                          name: "label",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={9} key={`value-${__.id}`}>
                      <Input
                        placeholder={FORM.REQUIREMENTS.OPTIONS.VALUE}
                        {...bindArrayInputProps({
                          parent: `requirements.${selected.index}.options`,
                          index: idx,
                          name: "value",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`delete-${__.id}`}>
                      <div
                        className={"delete-icon"}
                        onClick={() => arrayHelpers.remove(idx)}
                      >
                        <DeleteOutlined
                          style={{ color: "#f00", fontSize: 22 }}
                        />
                      </div>
                    </Col>,
                  ]
                ),
                <Button
                  key="add-row"
                  onClick={() => arrayHelpers.push({ id: cuid() })}
                  bg={"#5462AA"}
                  title={FORM.BUTTON}
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                />,
              ]}
            />
          </Row>
        }
      />
    ),
  };

  const template = get(TEMPLATES, selected.visible);

  return (
    <Formik
      initialValues={{
        name: "",
        isDefault: false,
        isBlocking: false,
        hasReminder: false,
        requirements: [],
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, values, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          okText={CREATE.HEADER.SAVE}
          hideButtons={!isValid}
          cancelText={CREATE.HEADER.DISCARD}
          onCancelClick={cancel}
          onOkClick={handleSubmit}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={24}>
                <Label placeholder={FORM.ID} value={id} />
              </Col>
              <Col lg={9}>
                <Select
                  options={Object.values(QUALIFIER_TYPES).map((el) => ({
                    label: QUALIFIERS[el],
                    value: el,
                  }))}
                  label={FORM.NAME}
                  placeholder={FORM.NAME}
                  {...bindInputProps({ name: "name", ...formProps })}
                />
              </Col>
              <Col lg={9}>
                <Input
                  placeholder={FORM.PRIORITY}
                  {...bindInputProps({ name: "priority", ...formProps })}
                />
              </Col>
              <Col lg={2}>
                <Input
                  type="checkbox"
                  placeholder={FORM.DEFAULT}
                  {...bindInputProps({ name: "isDefault", ...formProps })}
                  checked={values.isDefault}
                />
              </Col>
              <Col lg={2}>
                <Input
                  type="checkbox"
                  placeholder={FORM.BLOCKING}
                  {...bindInputProps({ name: "isBlocking", ...formProps })}
                  checked={values.isBlocking}
                />
              </Col>
              <Col lg={2}>
                <Input
                  type="checkbox"
                  placeholder={FORM.REMINDER}
                  {...bindInputProps({ name: "hasReminder", ...formProps })}
                  checked={values.hasReminder}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
        <Row gutter={[16, 16]} key="statuses">
          <Col span={24}>
            <Form title={FORM.REQUIREMENTS.TITLE} key="form">
              <FieldArray
                name={"requirements"}
                render={(arrayHelpers) => [
                  values.requirements.map((_, i) => [
                    <Col lg={9} key={`name-${_.id}`}>
                      <Input
                        placeholder={FORM.REQUIREMENTS.NAME}
                        {...bindArrayInputProps({
                          parent: "requirements",
                          index: i,
                          name: "name",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={9} key={`type-${_.id}`}>
                      <Select
                        placeholder={FORM.REQUIREMENTS.TYPE}
                        options={Object.entries(INPUT_TYPES).map(
                          ([key, value]) => ({
                            label: FORM.INPUT.TYPES[key],
                            value,
                          })
                        )}
                        name={`requirements.${i}.type`}
                        onChange={(value) => {
                          setFieldValue(`requirements.${i}.type`, value);
                          setSelected({ visible: value, index: i });
                        }}
                      />
                    </Col>,
                    <Col lg={2} key={`required-${_.id}`}>
                      <Input
                        type="checkbox"
                        placeholder={FORM.REQUIREMENTS.REQUIRED}
                        {...bindArrayInputProps({
                          parent: "requirements",
                          index: i,
                          name: "required",
                          values,
                          ...formProps,
                        })}
                        checked={values.requirements[i].required}
                      />
                    </Col>,
                    <Col lg={2} key={`delete-${_.id}`}>
                      <div
                        className={"delete-icon"}
                        onClick={() => arrayHelpers.remove(i)}
                      >
                        <DeleteOutlined
                          style={{ color: "#f00", fontSize: 22 }}
                        />
                      </div>
                    </Col>,
                  ]),
                  <Button
                    key="add-row"
                    onClick={() =>
                      arrayHelpers.push({ id: cuid(), required: false })
                    }
                    bg={"#5462AA"}
                    title={FORM.BUTTON}
                    icon={<PlusOutlined style={{ color: "#fff" }} />}
                  />,
                ]}
              />
              ,
            </Form>
          </Col>
        </Row>,
        // template({ translation: FORM, values, formProps }),
      ]}
    </Formik>
  );
};

export default Create;
