import cuid from "cuid";
import dayjs from "dayjs";
import {
  defaults,
  defaultsDeep,
  filter,
  find,
  get,
  head,
  isEmpty,
  isString,
  keyBy,
  map,
  set,
} from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENT } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COST } from "utils/api/graphql/mutations/costs";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  ACTION_TYPES,
  CASE_TYPES,
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  PROCESS_TYPES,
  RELATIONSHIP,
  SCREENS,
  SUBSCRIPTION_STATUSES,
  TEMPLATES,
  TEMPLATE_TYPES,
  TRACER_TYPES,
} from "utils/constants";
import { filterByConditions } from "../../../..";
import Loading from "../Components/Spin";
import View from "./View";

const Subscription = ({ project, onNext, onBack, updateUrl }) => {
  const [franceAddressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const subscriptionId = get(project, "fields.currentSubscription");
  const [initialValues, setInitialValues] = useState(null);
  const navigate = useNavigate();
  const [getTracer] = useLazyQuery(TRACER);

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const insurancePlans = useMemo(
    () =>
      get(project, "fields.insurancePlans", [])?.map((id) => ({
        AND: [
          {
            insurancePlans: { id },
          },
          { project: { id: project.id } },
        ],
      })),
    [project]
  );
  const attachmentsFilter = {
    where: {
      OR: [
        {
          project: {
            id: project.id,
          },
        },
        ...insurancePlans,
      ],
    },
  };
  const [generateQuoteDocument] = useMutation(GENERATE_QUOTE_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.ATTACHMENTS,
        awaitRefetchQueries: true,
        variables: attachmentsFilter,
      },
    ],
  });

  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT);
  const duplicateProjectStatus = ({ Template, prevTemplate, fields }) => {
    getTracer({
      fetchPolicy: "no-cache",
      variables: {
        where: { type: TRACER_TYPES.COMPARISON },
      },
      onCompleted: ({ tracer }) => {
        const status = findStatus(
          get(tracer, "flow.process.processStatuses"),
          Template
        );
        const prevStatus = findStatus(
          get(tracer, "flow.process.processStatuses"),
          prevTemplate
        );
        const actionsToExecute = filterByConditions(
          get(prevStatus || status, "actions", []),
          project
        );
        const syncActions = actionsToExecute
          .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
          .map(({ args: { status } }) => ({
            id: cuid(),
            status: { id: status },
          }));

        duplicateProject({
          variables: {
            where: { id: project.id },
            data: {
              statuses: [
                { id: cuid(), status: { id: status.id } },
                ...syncActions,
              ],
              fields,
            },
          },
          onCompleted: ({ duplicateProject }) => {
            localStorage.setItem("projectId", duplicateProject?.id);
            navigate(
              `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
              {
                state: {
                  process: get(omitDeep(tracer, "__typename"), "flow.process"),
                  tracer: omitDeep(tracer, "__typename"),
                },
              }
            );
          },
        });
      },
    });
  };

  const updateProjectStatus = ({ template }) => {
    getTracer({
      fetchPolicy: "no-cache",
      variables: {
        where: { type: TRACER_TYPES.COMPARISON },
      },
      onCompleted: ({ tracer }) => {
        const status = findStatus(
          get(tracer, "flow.process.processStatuses"),
          template
        );
        const actionsToExecute = filterByConditions(
          get(status, "actions", []),
          project
        );
        const syncActions = actionsToExecute
          .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
          .map(({ args: { status } }) => ({
            id: cuid(),
            status: { id: status },
          }));
        updateProject({
          variables: {
            where: { id: project.id },
            data: {
              statuses: [
                { id: cuid(), status: { id: status.id } },
                ...syncActions,
              ],
            },
          },
          onCompleted: ({ updateProject }) => {
            navigate(
              `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
              {
                state: {
                  process: get(omitDeep(tracer, "__typename"), "flow.process"),
                  tracer: omitDeep(tracer, "__typename"),
                },
              }
            );
          },
        });
      },
    });
  };

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const recalculateCost = ({
    cost,
    issuanceDate,
    oldIssuanceDate,
    setFieldValue,
    setShowChangeCostModal,
    isCancel,
    isRia,
  }) => {
    const formattedIssuanceDate = isString(issuanceDate)
      ? moment(issuanceDate)
      : issuanceDate;

    const formattedOldIssuanceDate = isString(oldIssuanceDate)
      ? moment(oldIssuanceDate)
      : oldIssuanceDate;

    if (formattedIssuanceDate.isSame(formattedOldIssuanceDate.format(), "day"))
      return;
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          contract: {
            id: get(project, "contract.id"),
            issuanceDate,
          },
        },
      },
      onCompleted: () => {
        buildGeneralCost({
          onCompleted: ({ buildGeneralCost: generalCost }) => {
            if (!generalCost.cost) {
              setFieldValue("project.contract.issuanceDate", oldIssuanceDate);
              setShowChangeCostModal({
                noCost: true,
              });
              return updateProject({
                variables: {
                  where: { id: project.id },
                  data: {
                    contract: {
                      id: get(project, "contract.id"),
                      issuanceDate: oldIssuanceDate,
                    },
                  },
                },
                onCompleted: () => {
                  buildGeneralCost();
                },
              });
            }
            if (cost === generalCost.cost) return;
            if (isCancel) {
              setFieldValue("project.contract.issuanceDate", issuanceDate);
              refetch();
              return setShowChangeCostModal({});
            }
            setShowChangeCostModal({
              isRia,
              issuanceDate: {
                old: formattedOldIssuanceDate.format(DATE_FORMAT),
                new: formattedIssuanceDate.format(DATE_FORMAT),
              },
              cost: {
                old: cost,
                new: generalCost.cost,
              },
            });
            refetch();
          },
        });
      },
    });
  };

  const onPersonalInformationEdit = () => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      ...fields
    } = project?.fields;
    duplicateProjectStatus({
      Template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_TELECOMS,
      prevTemplate:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      fields,
    });
  };

  const onSocialRegimeEdit = () => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      ...fields
    } = project?.fields;

    duplicateProjectStatus({
      Template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      prevTemplate:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      fields,
    });
  };

  const onProductEdit = () => {
    // onBack();
    // const {
    //   insurancePlans,
    //   currentSubscription,
    //   nextQuoteStatus,
    //   selectedToCompare,
    //   ...fields
    // } = project?.fields;

    // duplicateProjectStatus({
    //   Template:
    //     TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE].PRODUCTS,
    //   prevTemplate:
    //     TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
    //       .ADHERENT_TELECOMS,
    //   fields,
    // });
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE].PRODUCTS,
    });
  };

  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  const [buildGeneralCost] = useMutation(BUILD_GENERAL_COST, {
    variables: {
      data: {
        project: { id: project.id },
        insurancePlan: {
          id: get(data, "subscription.insurancePlan.id"),
        },
      },
    },
  });

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: ({ subscription: data }) => {
          const zipCode = get(
            find(get(project, "contact.telecoms", []), {
              system: CONTACT_POINT_SYSTEM.ZIP_CODE,
            }),
            "value",
            ""
          );
          franceAddressZipCodeCity({
            variables: {
              where: { zipCode },
            },
            onCompleted: (_) => {
              getGeneralCost();
              setCities(get(_, "franceAddressZipCodeCity.response"));
              const subscription = omitDeep(data, "__typename");
              if (isEmpty(subscription)) return;
              const children = filter(
                get(subscription, "project.contact.relatedPersons", []),
                {
                  relationship: RELATIONSHIP.CHILD,
                }
              );
              const spouse = get(
                subscription,
                "project.contact.relatedPersons",
                []
              ).find(
                ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
              );
              const contactTelecoms = get(
                subscription,
                "project.contact.telecoms",
                []
              );
              const telecoms = keyBy(contactTelecoms, "system");
              set(
                telecoms,
                CONTACT_POINT_SYSTEM.BIRTH_COUNTRY,
                get(telecoms, CONTACT_POINT_SYSTEM.BIRTH_COUNTRY, {
                  id: cuid(),
                  system: CONTACT_POINT_SYSTEM.BIRTH_COUNTRY,
                  value: TRANSLATION.DEFAULTS.BIRTH_COUNTRY,
                })
              );
              if (isEmpty(get(subscription, "project.contact.nationality")))
                set(
                  subscription,
                  "project.contact.nationality",
                  TRANSLATION.DEFAULTS.NATIONALITY
                );

              if (get(_, "franceAddressZipCodeCity.response").length === 1)
                set(telecoms, CONTACT_POINT_SYSTEM.CITY, {
                  id: cuid(),
                  system: CONTACT_POINT_SYSTEM.CITY,
                  value: get(
                    head(get(_, "franceAddressZipCodeCity.response")),
                    "label"
                  ),
                });

              const initialFormValues = {
                resiliation:
                  get(subscription, "project.ria") ||
                  get(subscription, "project.fields.selfOccupation"),
                project: get(subscription, "project"),
                telecoms,
                children,
                spouse: isEmpty(spouse)
                  ? undefined
                  : defaultsDeep(spouse, {
                      identity: { fields: { teletransmission: true } },
                    }),
              };
              setLoading(false);
              setInitialValues(initialFormValues);
            },
          });
        },
      });
  }, []);

  const onSubmit = ({ telecoms, spouse, children, project: projectValues }) => {
    const isEffectiveDateChanged = !dayjs(
      get(projectValues, "contract.issuanceDate")
    ).isSame(dayjs(get(project, "contract.issuanceDate")));
    const subscription = omitDeep(get(data, "subscription"), "__typename");
    set(telecoms, CONTACT_POINT_SYSTEM.ADDRESS, {
      id: cuid(),
      system: CONTACT_POINT_SYSTEM.ADDRESS,
      value: get(telecoms, "ADDRESS.value"),
    });
    const contactTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    set(subscription, "project", {
      ...projectValues,
      terminationRequest: {
        id: cuid(),
        ...projectValues.terminationRequest,
        brokerageMandate: {
          id: cuid(),
          ...projectValues.terminationRequest?.brokerageMandate,
        },
      },
    });
    set(subscription, "project.contact.telecoms", contactTelecoms);
    const relatedPersons = [
      ...(!isEmpty(spouse)
        ? [
            defaultsDeep(spouse, {
              id: cuid(),
              relationship: RELATIONSHIP.SPOUSE,
              identity: { id: cuid(), user: { id: cuid() } },
            }),
          ]
        : []),
      ...children.map((child) =>
        defaultsDeep(child, {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: { id: cuid(), user: { id: cuid() } },
        })
      ),
    ];
    set(subscription, "project.contact.relatedPersons", relatedPersons);
    set(
      subscription,
      "project.contract.caseType",
      get(subscription, "project.ria")
        ? CASE_TYPES.RETURN_TO_COMPETITION
        : CASE_TYPES.NEW_CONTRACT
    );
    set(subscription, "status", SUBSCRIPTION_STATUSES.IN_PROGRESS);
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: subscription,
      },
      onCompleted: (_) => {
        if (isEffectiveDateChanged)
          generateQuoteDocument({
            variables: {
              data: {
                project: { id: get(project, "id") },
                insurancePlans: [{ id: get(subscription, "insurancePlan.id") }],
              },
            },
          });
        updateUrl({ subscriptionId, url: process.env.REACT_APP_B2C_URL });
        onNext({
          payload: {},
        });
      },
    });
  };

  const [getGeneralCost, { data: generalCost, refetch }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(project, "id") },
          insurancePlan: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
      },
    }
  );

  if (loading || !initialValues) return <Loading />;

  return (
    <View
      subscription={get(data, "subscription")}
      onBack={onBack}
      initialValues={initialValues}
      generalCost={get(generalCost, "generalCost")}
      onPersonalInformationEdit={onPersonalInformationEdit}
      onSocialRegimeEdit={onSocialRegimeEdit}
      onProductEdit={onProductEdit}
      onSubmit={onSubmit}
      cities={cities}
      translate={TRANSLATION}
      recalculateCost={recalculateCost}
    />
  );
};

export default Subscription;
