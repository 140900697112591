import { gql } from "@apollo/client";

export const GENERAL_COSTS = gql`
  query generalCosts(
    $where: GeneralCostWhereInput
    $gt: GeneralCostWhereInput
    $lt: GeneralCostWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
  ) {
    generalCosts(
      where: $where
      take: $take
      skip: $skip
      isIn: $isIn
      gt: $gt
      lt: $lt
    ) {
      data {
        id
        cost
        currency
        commission {
          id
        }
        insurancePlan {
          id
          icon
          name
          description
          distributions {
            id
            broker {
              id
            }
            commissions {
              id
              value
              type
              withholding
            }
          }
          params {
            rules {
              id
              name
              value
            }
          }
          insurancePlanCoverages {
            id
            needs {
              id
              level
            }
            coverageCategory {
              id
              value
              label
              icon
              order
            }
            coverageMappings {
              id
              coverage {
                id
                type
                subtype
                additionalInfo
                requirement
                order
              }
              supportingInformation {
                id
                category
                value
              }
              limits {
                id
                code
                value
              }
            }
          }
        }
        project {
          id
        }
      }
      count
    }
  }
`;

export const GENERAL_COST = gql`
  query generalCost($where: GeneralCostWhereInput) {
    generalCost(where: $where) {
      cost
      commission {
        id
      }
      insurancePlan {
        id
        name
        description
      }
      project {
        id
      }
    }
  }
`;

const generalCosts = { GENERAL_COSTS, GENERAL_COST };

export default generalCosts;
