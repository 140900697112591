import 'material-symbols';
import PropTypes from 'prop-types';

const Icon = ({
  name,
  size = 24,
  weight = 400,
  fill = false,
  color = 'currentColor',
  variant = 'outlined',
  className = ''
}) => {
  const variantClass = `material-symbols-${variant}`;

  return (
    <span
      className={`${variantClass} ${className}`}
      style={{
        fontSize: `${size}px`,
        fontWeight: weight,
        color,
        fill: fill ? 1 : 0,
      }}
    >
      {name}
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700]),
  fill: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'rounded', 'sharp']),
  className: PropTypes.string
};

export default Icon;
