import { useMemo } from "react";

import PropTypes from "prop-types";

import Checkbox from "./Checkbox";
import Color from "./Color";
import ColorPicker from "./ColorPicker";
import Date from "./Date";
import Email from "./Email";
import File from "./File";
import Number from "./Number";
import Password from "./Password";
import Phone from "./Phone";
import Radio from "./Radio";
import Search from "./Search";
import Simple from "./Simple";
import TextArea from "./TextArea";

const DIRECTIONS = ["column", "row"];

const MODES = {
  simple: Simple,
  search: Search,
  radio: Radio,
  date: Date,
  number: Number,
  email: Email,
  phone: Phone,
  file: File,
  textarea: TextArea,
  password: Password,
  checkbox: Checkbox,
  color: Color,
  colorPicker: ColorPicker,
};

const Input = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
  direction: PropTypes.oneOf(DIRECTIONS),
};

Input.defaultProps = {
  type: "simple",
  direction: "column",
};

export default Input;
