import { Switch } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineInsertComment } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Information = ({
  values,
  setFieldValue,
  errors,
  initialValues,
  formProps,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const INFORMATION = t(
    "SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.INFORMATION",
    {
      returnObjects: true,
    }
  );

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION}-section`}
    >
      <Form type="vertical" title={INFORMATION.TITLE}>
        <div className="grid--1">
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CSH_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                initialValues,
                "project.fields.info.cssBeneficiary"
              )}
              onChange={(e) => {
                set(initialValues, "project.fields.info.cssBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CC_CHI_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                initialValues,
                "project.fields.info.ccChiBeneficiary"
              )}
              onChange={(e) => {
                set(initialValues, "project.fields.info.ccChiBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.PROTECTION_REGIME}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                initialValues,
                "project.fields.info.protectionRegime"
              )}
              onChange={(e) => {
                set(initialValues, "project.fields.info.protectionRegime", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.POLITICALLY_EXPOSED_PERSON.LABEL}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                initialValues,
                "project.contact.politicallyExposedPerson"
              )}
              onChange={(e) => {
                set(
                  initialValues,
                  "project.contact.politicallyExposedPerson",
                  e
                );
              }}
            />
          </div>
          <Input
            type="textarea"
            label={INFORMATION.COMMENT}
            placeholder={INFORMATION.COMMENT}
            icon={<MdOutlineInsertComment size={16} />}
            defaultValue={get(initialValues, "project.fields.info.comment")}
            errors={errors["project.fields.info.comment"]}
            touched={!isEmpty(errors["project.fields.info.comment"])}
            onChange={({ target: { value } }) => {
              set(initialValues, "project.fields.info.comment", value);
            }}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Information;
