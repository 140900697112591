const Count = ({ icon, label, value }) => {
  return (
    <div className="card__count">
      {icon && <span className="card__count--icon">{icon}</span>}
      <div className="card__count--content">
        <span className="card__count--label">{label}</span>
        <span className="card__count--value">{value}</span>
      </div>
    </div>
  );
};

export default Count;
