import { CopyOutlined } from "@ant-design/icons";
import { message } from "antd";
import cuid from "cuid";
import { useState } from "react";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { useMutation } from "shared/hooks/useApi";
import { DOMAIN_SIGN_UP } from "utils/api/graphql/mutations/users";
import { USER_TYPES } from "utils/constants";

function IframeGenerator({ organizationId, translation }) {
  const [iframeSrc, setIframeSrc] = useState("");
  const [url, setUrl] = useState("");
  const [addDomain] = useMutation(DOMAIN_SIGN_UP);

  const handleGenerateIframeCode = async () => {
    addDomain({
      variables: {
        data: {
          id: cuid(),
          name: url,
          meta: { type: USER_TYPES.INSURANCE_GUEST.type },
          organization: {
            id: organizationId,
          },
        },
      },
    });
    const iframeCode = `<div id="parcours_b2c"></div>
    <script src="https://subdomain.kayros.tech/script.min.js"></script>
    <script>
      const host = window.location.host;
      const project = getQueryVariable("p");
      const subscription = getQueryVariable("s");
      var iframe = document.createElement("iframe");
      const status = getQueryVariable("next");
      const link = getLink(
        "${process.env.REACT_APP_BASE_URL}/#/public/process/init/63d1817df03b8d4918de893?p=projectId&s=subscriptionId&host=hostId&next=statusId",
        { host, project, subscription,status }
      );
      iframe.setAttribute("src",link);
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.setAttribute("frameBorder", "0");
      iframe.style.height = "100vh";
      var targetDiv = document.getElementById("parcours_b2c");
      targetDiv.parentNode.insertBefore(iframe, targetDiv.nextSibling);
    </script>`;
    setIframeSrc(iframeCode);
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        setUrl("");
        message.success(translation.SUCCESS);
      })
      .catch(() => {
        message.error(translation.ERROR);
      });
  };

  return (
    <div className="grid--1">
      <Input
        value={url}
        placeholder={translation.SET_URL}
        label={translation.URL}
        onChange={({ target: { value } }) => setUrl(value)}
      />
      <Button type="primary" onClick={handleGenerateIframeCode} disabled={!url}>
        {translation.GENERATE}
      </Button>
      <Input
        type="textarea"
        readOnly
        autoSize={{ minRows: 4, maxRows: 6 }}
        value={iframeSrc}
      />
      <Button
        type="default"
        icon={<CopyOutlined />}
        onClick={() => {
          navigator.clipboard.writeText(iframeSrc);
          message.success(translation.SUCCESS);
        }}
        disabled={!iframeSrc}
      >
        {translation.COPY}
      </Button>
    </div>
  );
}

export default IframeGenerator;
