import { gql } from "@apollo/client";

export const QUOTES = gql`
  query Quotes($where: QuoteWhereInput, $take: Int, $skip: Int) {
    quotes(where: $where, take: $take, skip: $skip) {
      data {
        id
      }
      count
    }
  }
`;

export const COUNT_QUOTES = gql`
  query countQuotes(
    $where: QuoteWhereInput
    $gt: QuoteWhereInput
    $lt: QuoteWhereInput
  ) {
    countQuotes(where: $where, gt: $gt, lt: $lt)
  }
`;

export const QUOTE = gql`
  query Quote($where: QuoteWhereInput) {
    quote(where: $where) {
      id
      project {
        id
        name
        expired
        statuses {
          id
          createdDate
          status {
            id
            template
            name
            progress
            order
            moveToNext
            possibleStatuses {
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            process {
              id
            }
          }
        }
        fields
        generalCosts {
          id
          cost
          currency
          commission {
            id
          }
          insurancePlan {
            id
            icon
            name
            description
            distributions {
              id
              broker {
                id
              }
              commissions {
                id
                value
                type
                withholding
              }
            }
            params {
              id
            }
            insurancePlanCoverages {
              id
              coverageCategory {
                id
                value
                label
                icon
                order
              }
              coverageMappings {
                id
                coverage {
                  id
                  type
                  subtype
                  additionalInfo
                  requirement
                  order
                }
                supportingInformation {
                  id
                  category
                  value
                }
                limits {
                  id
                  code
                  value
                }
              }
            }
          }
          project {
            id
          }
        }
        contact {
          id
          name
        }
      }
    }
  }
`;

const quotes = { QUOTES, COUNT_QUOTES, QUOTE };

export default quotes;
